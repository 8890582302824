<template>
    <my-layout class="protocol_main">
        <template #header>
            <div class="header">
                <img class="logo" src="../../assets/img/h5/logo_white.png"/>
                <span>用户协议与隐私声明</span>
                <img @click="$router.push('/login_h5')" class="close" src="../../assets/img/h5/close_icon.png"/>
            </div>
        </template>
        <template #body>
            <div class="box">
                <h3>【导言】</h3>
                <p>欢迎使用AI竞投的服务！</p>
                <br />
                <p>在您使用AI竞投提供的各项VIP会员及非会员服务（以下简称“服务”）之前，请务必仔细阅读并透彻理解本协议，特别是免除或者限制责任的条款、管辖与法律适用条款，以及开通或使用某项服务的单独协议。如您不同意本协议及其更新内容，您可以主动取消AI竞投提供的服务；您一旦使用AI竞投的服务，即视为您已了解并完全同意本协议的各项内容， 并成为AI竞投用户（以下简称“用户”）。AI竞投有权在必要时修改用户协议，一旦本协议的内容发生变动，AI竞投将会在相关的页面上提示修改内容。变动结果将在AI竞投通知之日起生效。如果您不同意所改动的内容，您有权停止使用AI竞投的服务，如果您继续使用AI竞投的各项服务，则视为接受服务条款的变动。用户理解并同意：AI竞投具有保留修改或中断部分或全部服务的权利。</p>
                <br />
                <h3>一、【协议的范围】</h3>
                <p>1.1 本协议是您与AI竞投之间关于用户使用AI竞投相关服务所订立的协议。“AI竞投”是指上海凭安网络科技有限公司及其相关服务可能存在的运营关联单位。“用户”是指使用AI竞投相关服务的使用人，在本协议中更多地称为“您”。</p>
                <p>1.2 本协议项下的服务是指AI竞投向用户提供的包括但不限于信息交流等产品及服务（以下简称“本服务”）。</p>
                <p>1.3 本协议内容同时包括《隐私政策》, 且您在使用AI竞投某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何业务规则、单独协议的接受，即视为您对本协议全部的接受。</p>
                <br />
                <h3>二、【服务内容】</h3>
                <p>AI竞投（网址为：aijingtou.com）是一个竞品营销情报大数据平台，向用户提供功能强大、响应迅捷的竞品企业及企业广告推广数据的搜索和监测服务，该服务基于用户所提出和输入的指令，经过检索、分析、计算、辨识等复杂的系统处理后，最终生成检索与识别结果并呈现给用户。</p>
                <br />
                <h3>三、【服务声明】</h3>
                <p>3.1 AI竞投的所有权、经营权、管理权均属上海凭安网络科技有限公司。</p>
                <p>3.2 AI竞投目前展示的数据来源为互联网公开信息，包括但不限于以下网站：</p>
                <p>——全国企业信用信息公示系统（http://www.gsxt.gov.cn/index.html）</p>
                <p>——ICP/IP地址/域名信息备案管理系统（http://beian.miit.gov.cn）</p>
                <p>——中国商标网（http://sbj.cnipa.gov.cn/）</p>
                <p>——市场主体官方网站</p>
                <p>——各大主流垂直行业网站</p>
                <p>3.3 AI竞投自身不主动编辑或修改被所公示网站上的信息的内容或其表现形式，仅对相关网站依法公示的信息如实展示。因此，AI竞投无法保证该数据与信息的完整的真实与时效性。</p>
                <br />
                <h3>四、【帐号与密码安全】</h3>
                <p>4.1 您在使用AI竞投的服务时需要购买一个认证的帐号。</p>
                <p>4.2 针对您提供的认证资料，您同意并保证：</p>
                <p>（1）如果您提供的注册资料中存在不合法、不真实、不准确的，您须承担因此引起的相应责任及后果，并且AI竞投保留随时终止您使用AI竞投各项服务的权利。</p>
                <p>（2）AI竞投用户名和昵称中不能含有任何侮辱、威胁、淫秽、谩骂等侵害他人合法权益的文字。</p>
                <p>（3） 若因输入的个人信息不真实而引发的一切问题、纠纷及所带来的后果，由您个人独立承担，AI竞投不负任何责任。</p>
                <p>4.3 您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。</p>
                <br />
                <h3>四、【帐号与密码安全】</h3>
                <p>4.1 您在使用AI竞投的服务时需要购买一个认证的帐号。</p>
                <p>4.2针对您提供的认证资料，您同意并保证：</p>
                <p>（1）如果您提供的注册资料中存在不合法、不真实、不准确的，您须承担因此引起的相应责任及后果，并且AI竞投保留随时终止您使用AI竞投各项服务的权利。</p>
                <p>（2）AI竞投用户名和昵称中不能含有任何侮辱、威胁、淫秽、谩骂等侵害他人合法权益的文字。</p>
                <p>（3） 若因输入的个人信息不真实而引发的一切问题、纠纷及所带来的后果，由您个人独立承担，AI竞投不负任何责任。</p>
                <p>4.3 您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。</p>
                <br />
                <h3>五、【隐私权政策】</h3>
                <p>5.1. 保护用户隐私是AI竞投的一项基本政策，未经用户许可AI竞投不得向任何第三方提供、公开或共享用户注册资料中的个人信息（如联系方式），但下列情况除外：</p>
                <p>（1）用户或用户监护人授权AI竞投披露的；</p>
                <p>（2）有关法律要求AI竞投披露的；</p>
                <p>（3）司法机关或者行政机关基于法定程序要求AI竞投提供的；</p>
                <p>（4）用户为了维护自己合法权益而向AI竞投提起诉讼或者仲裁时；</p>
                <p>（5）为维护社会公众的利益。</p>
                <p>5.2. 在不透露单个用户隐私资料的前提下，AI竞投有权对整个用户数据库进行技术分析并对已进行分析、整理后的用户数据进行商业上的利用。尽管AI竞投对用户的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使用户的技术信息等不受任何形式的损失。</p>
                <p>5.3. 用户在注册为AI竞投的用户后，理解并同意AI竞投向其提供增值服务。该增值服务适用于上海凭安网络科技有限公司及其关联方所开发的相关产品，包括但不限于水滴信用、可信百科、中国企业信用档案库等。用户可以退订或拨打客服电话取消该增值服务。</p>
                <br />
                <h3>六、【使用本服务的方式】</h3>
                <p>6.1 除非您与AI竞投另有约定，您同意本服务仅为您个人及相关授权账号非商业性质的使用。</p>
                <p>6.2 您应当通过AI竞投提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让。</p>
                <p>6.3 您不得使用未经AI竞投授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。</p>
                <br />
                <h3>七、【按现状提供服务】</h3>
                <p>您理解并同意， AI竞投的服务是按照现有技术和条件所能达到的现状提供的。AI竞投会尽最大努力向您提供服务，确保服务的连贯性和安全性；但AI竞投不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</p>
                <br />
                <h3>八、【知识产权声明】</h3>
                <p>8.1　AI竞投在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归AI竞投所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。</p>
                <p>8.2　除另有特别声明外，AI竞投提供本服务时所依托软件的著作权、专利权及其他知识产权均归AI竞投所有。</p>
                <p>8.3　AI竞投在本服务中所使用的商业标识，其著作权或商标权归AI竞投所有。</p>
                <p>8.4　上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经AI竞投、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。</p>
                <br />
                <h3>九、【用户违法行为】</h3>
                <p>9.1 您在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：</p>
                <p>（1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；</p>
                <p>（2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；</p>
                <p>（3）恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
                <p>（4）发布、传送、传播广告信息及垃圾信息；</p>
                <p>（5）冒用他人名义使用本服务；</p>
                <p>（6）从事不法交易行为， 如洗钱、恐怖融资、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他本公司认为不得使用本服务进行交易的物品等；</p>
                <p>（7）非法使用他人银行账户（包括信用卡账户）或无效银行账户（包括信用卡账户）交易；</p>
                <p>（8）从事任何可能含有电脑病毒或是可能侵害本服务系统、资料之行为；</p>
                <p>（9）其他法律法规禁止的行为或AI竞投有正当理由认为不适当之行为。</p>
                <p>9.2 如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求AI竞投给予协助。造成损害的，您应依法予以赔偿，AI竞投不承担任何责任。</p>
                <p>9.3 如果AI竞投发现或收到他人举报您发布的信息违反本条约定，AI竞投有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，AI竞投有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用，追究法律责任等措施。</p>
                <p>9.4 您违反本条约定，导致任何第三方损害的，您应当独立承担责任；AI竞投因此遭受损失的，您也应当一并赔偿。</p>
                <br />
                <h3>十、【产品风险提示】</h3>
                <p>10.1 AI竞投是通过互联网向您提供服务的，若互联网故障会导致AI竞投无法正常向您提供服务。AI竞投不对互联网故障、延迟等原因导致的信息延迟、错误负责。</p>
                <p>10.2 对于AI竞投提供的信息数据内容，您不应将其视作承诺信息全部符合实际情况。</p>
                <br />
                <h3>十一、【账号使用规范提示】</h3>
                <p>11.1 您知悉并同意，一个账号同一时间仅能在一台设备上登录，若存在一个账号在两台或两台以上的设备同时登录的情形，则先登录的账号会被迫下线而AI竞投无需承担任何责任。</p>
                <p>11.2 您知悉并同意，您VIP会员账号内的行为（包括但不限于信息查询、信息发布等）均代表您本人行为，您应妥善保管您的账号及密码，并对该账号下的所有行为承担责任。</p>
                <p>11.3 为保障您的账号安全，确保您的账号不被他人违规使用，您不应通过AI竞投官方渠道之外的其他途径获取或使用AI竞投账号。否则，AI竞投有权视具体违规情节，采取强制修改密码直至封禁相关VIP会员账号等措施，暂停或终止为您提供服务，并无须退还任何费用。违规使用情形包括：</p>
                <p>（1）未经AI竞投事先明示同意，转让、借用、出租、赠与或以其他方式，为他人使用您的账号提供条件；</p>
                <p>（2）未经AI竞投事先明示同意，通过爬虫工具、机器人软件等手段获取会员服务或有关数据信息；</p>
                <p>（3）通过系统漏洞、黑色产业、滥用会员身份等不正当手段，侵犯AI竞投或他人合法权益；</p>
                <p>违反上述规定的，AI竞投有权终止对您进行服务，并协助互联网有关行政机关等进行追索和查处。</p>
                <br />
                <h3>十二、【服务的变更、中断、终止】</h3>
                <p>12.1 AI竞投可能会对服务内容进行变更，也可能会中断、中止或终止服务。</p>
                <p>12.2 如发生下列任何一种情形，AI竞投有权不经通知而中断或终止向您提供的服务：</p>
                <p>（1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</p>
                <p>（2）您违反相关法律法规或本协议的约定；</p>
                <p>（3）按照法律规定或主管部门的要求；</p>
                <p>（4）出于安全的原因或其他必要的情形。</p>
                <p>12.3 您有责任自行备份存储在本服务中的数据。如果您的服务被终止，AI竞投没有义务向您返还数据。</p>
                <br />
                <h3>十三、【附则】</h3>
                <p>13.1 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。若因本协议签订及服务履行过程中所发生争议，双方应尽量友好协商解决；</p>
                <p>13.2 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效且具有约束力。</p>
                <p>13.3 AI竞投有权随时修改本协议的任何条款，一旦本协议的内容发生变动，AI竞投将会在相关的页面上提示修改内容。修改后的用户协议一旦在页面上公布即有效代替原来的用户协议。如不同意AI竞投对本协议相关条款所做的修改，用户有权停止使用AI竞投服务。如果用户继续使用AI竞投服务，则视为用户接受AI竞投对本协议相关条款所做的修改。 </p>
            </div>
        </template>
    </my-layout>
</template>

<script>
  export default {
    name: "protocol"
  }
</script>

<style scoped lang="scss">
.protocol_main {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 60px 39px;
        font-size: 54px;
        font-weight: bold;
        color: #ffffff;
        background-color: #1f81f8;
        .logo {
            width: 93px;
            height: 79px;
        }
        .close {
            width: 46px;
            height: 45px;
        }
    }
    .box {
        font-size: 36px;
        line-height: 60px;
        padding: 81px 29px 0 39px;
        color: #666666;
        h3 {

        }
    }

}
</style>